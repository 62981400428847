.AllDayTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.taskIconWrapper {
  width: var(--timeline-icon-size);
  min-height: var(--timeline-icon-size);
  border-radius: 30px;
  line-height: var(--timeline-icon-size);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-elevated-color);
  user-select: none;


  i {
    color: var(--task-color);
  }
}

.taskTitle {
  //display: block;
  margin-top: var(--s);
  text-align: center;
  font-size: var(--fs-s);
  font-weight: bold;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  width: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // to make simple ellipsis work. Unfortunately both at the same time is not possible
  //display: inline-block;
}



