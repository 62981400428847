.TaskEditForm {
  display: flex;
  flex-direction: column;
}

.sectionLabel {
  font-weight: bold;
  font-size: var(--fs-m);
  color: var(--fg-muted-color);
  display: block;
  margin-top: var(--s);
  margin-bottom: 0;
  padding: 0;
}

.taskTitleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskIcon {
  border-radius: var(--btn-border-radius);
  margin-right: var(--s);
  min-width: 40px;
  width: 40px;
  height: 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-elevated-color);
  cursor: pointer;
  user-select: none;

  i {
    font-size: var(--is-s);
  }
}

.taskTitleInput {
  border: none !important;
  font-size: var(--fs-l);
  font-weight: bold;
  color: var(--fg-color);
  background: transparent !important;
}


.dateTimeSection {
}

.durationSection {
}

.colorSection {
}

.repeatSection {
}

.alertSection {
}

.recurringSection {
  position: relative;
}

.subtaskSection {
  margin-top: var(--s2);
}

.submitBtn {
  width: auto;
  left: var(--s2);
  right: var(--s2);
  bottom: var(--s2);
  position: absolute;
}


.addSubtaskBtn {
  //padding: var(--ss) var(--s);
  //border-bottom-left-radius: 0;
  //border-bottom-right-radius: 0;
  margin-top: var(--s2);
  margin-bottom: var(--s2);
  font-size: var(--fs-m) !important;
}
