.App {
  overflow: hidden;
  display: flex;
  position: relative;
}

.main-container {
  flex: 1;
}

.btn.btn-float {
  position: fixed;
  right: 20px;
  bottom: 20px;
}


.nav-btns {
  position: absolute;
  right: var(--s2);
  top: var(--s2);
  display: flex;
  gap: var(--s);
}
