:root {
  --neutral-btn-color: rgb(200, 200, 200);
  --grid-btn-bg-color: rgb(240, 240, 240);
  --grid-btn-fg-color: var(--primary-color);
  --btn-bg-color: var(--primary-color);
}


@media (prefers-color-scheme: dark) {
  :root {
    --neutral-btn-color: rgb(120, 120, 120);
    --grid-btn-bg-color: rgb(240, 240, 240);
  }
}


button {
  user-select: none;
}

.btn-ico,
.btn-outline,
.btn-neutral,
.btn {
  background: var(--btn-bg-color);
  cursor: pointer;
  color: #fff;
  border: 0;
  font-size: var(--fs-l);
  border-radius: var(--btn-border-radius);
  padding: var(--s);
  font-weight: bold;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  &:not(:disabled):active {
    transform: scale(0.9);
  }

  &:disabled {
    background: var(--neutral-btn-color);
    opacity: 0.3;
  }

  &.s {
    padding: var(--ss) var(--s);
    font-size: var(--fs-m);

    i {
      font-size: var(--fs-l);
    }
  }

  > :nth-child(2) {
    margin-left: var(--s);
  }
}

.btn-outline {
  background: transparent;
  border: 1px solid var(--btn-bg-color);
  color: var(--btn-bg-color);
  font-weight: normal;
}

.btn-neutral {
  background: var(--neutral-btn-color);
}

.btn-float {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  line-height: 48px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, .2);

  i {
    font-size: var(--is-m);
    font-weight: bold;
  }
}

.btn-large {
  height: 52px;
  text-align: center;
  font-size: var(--fs-xl);
  justify-content: center;
}

.btn-ico {
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: var(--fs-s);
  text-align: center;
  color: var(--primary-color);
}
