:root {
  --modal-border-radius: 12px;
  --modal-bg-color: var(--main-bg-color);
  --modal-bd-color: var(--divider-color);
  --modal-overlay-bg-color: rgba(255, 255, 255, .75);
}

@media (prefers-color-scheme: dark) {
  :root {
    --modal-overlay-bg-color: rgb(0, 0, 0, .75);
  }
}


// We're using react modal

// NOTE: css class needs to be given to avoid inline styles overwriting ours
.ReactModal__Content {
  position: absolute;
  //background-color: papayawhip;
  max-width: 100%;
  width: 420px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  height: auto;
  max-height: 90%;
  border-radius: var(--modal-border-radius);
  background: var(--modal-bg-color);
  border: 1px solid var(--modal-bd-color);
  padding: var(--s2) var(--s2);
  transition: transform var(--standard-transition-duration) ease-in-out;
  transform: translate(-50%, -50%) scale(0.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &--after-open {
    transform: translate(-50%, -50%) scale(1);
  }

  &--before-close {
    transform: translate(-50%, -50%) scale(0.2);
  }
}

.NoOverFlowModal {
  overflow: hidden !important;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity var(--standard-transition-duration) ease-in-out;
  background: var(--modal-overlay-bg-color) !important;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.modalHeader,
.ReactModal__Content > header {
  padding: var(--s2) var(--s2);
  margin-left: calc(-1 * var(--s2));
  margin-right: calc(-1 * var(--s2));
  margin-top: calc(-1 * var(--s2));
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: block;
  border-bottom: 1px solid var(--divider-color);
  font-size: var(--fs-xl);
  font-weight: bold;
}

$btnSize: 24px;

.modalCloseBtn {
  position: absolute;
  top: 14px;
  right: 14px;
  border-radius: 50%;
  width: $btnSize;
  height: $btnSize;
  line-height: $btnSize;
  color: var(--fg-muted-color);
  text-align: center;
  cursor: pointer;

  &:hover {
    color: var(--fg-color);
  }

  i {
    font-weight: bold;
    font-size: $btnSize;
  }

  // enlarge click area
  &:after {
    content: "";
    position: absolute;;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
  }
}
