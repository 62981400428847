.GridBtn {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--s2);
  background: var(--grid-btn-bg-color);
  color: var(--grid-btn-fg-color);
  font-weight: bold;
  font-size: var(--fs-m);
  border: 0;
  border-radius: 8px;
  cursor: pointer;

  &.isHorizontal {
    flex-direction: row;
  }

  &:active {
    transform: scale(0.9);
  }
}

.btnIco {
  margin-bottom: var(--s);

  .GridBtn.isHorizontal & {
    margin-right: var(--s);
    margin-bottom: 0;
  }

}
