.EditRecurring {
  margin-bottom: var(--s);
}

.startEndLabel {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: var(--fs-s);
  color: var(--primary-color);
  font-weight: bold;
}

.btnWrapper {
  display: flex;
  justify-content: stretch;
  gap: var(--s);
  margin-top: var(--s);

  button {
    flex-grow: 1;
    justify-content: center;
  }
}

.weekDaySelectWrapper {
  display: flex;
  justify-content: stretch;
  gap: var(--s);
  margin-top: var(--s);

  button {
    flex-grow: 1;
    justify-content: center;
  }
}

.repeatWrapper {
  position: relative;

  label {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 0.7;
    margin: 0;
  }
}
