.TaskContextModal {
  // NOTE: needs to be defined otherwise class will not be applied x-D
  display: block;


}

$btnSize: 24px;


.closeBtn {
  top: 20px;
  right: 20px;
}

.taskHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--divider-color);
  padding-bottom: var(--s);
  // make room for modal close X
  padding-right: 21px;
}

$icoSize: 32px;
.taskIcon {
  height: $icoSize;
  width: $icoSize;
  line-height: $icoSize;
  text-align: center;
  margin-right: var(--s2);
  color: var(--task-color);
}

.taskHeaderMiddle {
  flex: 1;
}

.taskTimeframe {
  color: var(--fg-muted-color);
  font-size: var(--fs-s);
}

.taskTitle {
  font-weight: bold;
}

.content {
  margin-top: var(--s2);
}

.topRow {
  display: flex;
  gap: var(--s2);
  margin-bottom: var(--s2);

  > * {
    flex: 1;

  }
}

.bottomRow {
  display: flex;
  flex-grow: 1;

  > * {
    flex: 1;
  }
}


.subtasks {
  margin-bottom: var(--s2);
  padding-bottom: var(--ss);
}

.subtask {
  cursor: pointer;
  display: flex;
  margin-bottom: var(--s);
  align-items: center;
  user-select: none;
  border-bottom: 1px solid var(--divider-color);

  &.isChecked {
    text-decoration: line-through;
  }

  > input[type=checkbox] {
    margin-right: var(--s);
    accent-color: var(--primary-color);
    outline: none !important;
  }

}

.subtaskCheckbox {
  border-radius: 50%;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-right: var(--s);
  border: 2px solid var(--task-color);
}
