@import "src/styles/variables";


.AddTaskModal {
  width: 400px;
}

.isFixed {
  position: fixed !important;
  right: 100px !important;
  bottom: 20px !important;
  top: auto !important;
  left: auto !important;
  max-width: var(--add-task-modal-width) !important;
  transform: none !important;
  overflow: scroll;
}

.contentWrapper {
  padding-top: var(--s2);
  overflow-y: auto;
  margin-bottom: 80px;
  margin-right: calc(-1 * var(--s2));
  padding-right: var(--s2);
}

.submitBtn {
  width: auto;
  left: var(--s);
  right: var(--s);
  bottom: var(--s);
  position: absolute;
}

.taskTitleForm {
  display: flex;
  flex-direction: column;
}


