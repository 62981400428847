@import 'src/styles/variables';

.TimelineTimes {
  --progress: 50%;

  min-width: 80px;
  text-align: right;
  margin-right: var(--s4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  position: relative;

  &.isOverlapWarning {
    > :first-child::before {
      content: "!!! ";
    }
  }


  &:after,
  &:before {
    border: none;
    content: "";
    opacity: .3;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    border-right-width: 5px;
    right: -59px;
    border-right-style: solid;
    border-right-color: var(--divider-color);
  }


  &.dashed::after,
  &.dashed::before {
    top: 3px;
    bottom: 3px;
    border-right-style: dotted;
  }


  &:after {
    //right: -64px;
    //border-right-width: 5px;
    //width: 5px;
    mask-image: linear-gradient(180deg, black var(--progress), transparent var(--progress));
    border-right-color: var(--primary-color);
    opacity: 1;
  }
}

.TimelineTimes > div {
  font-size: var(--fs-s);
  color: var(--fg-muted-color);
}
