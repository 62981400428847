/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/MaterialIconsRounded.woff2) format('woff2');
}

i,
Ico,
.f7-icons,
.framework7-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  color: inherit;
  font-size: var(--is-m);
}

.iContrast {
  color: var(--contrast-fg-color);
}

.izI {
  font-size: inherit;
}

.izXs {
  font-size: var(--is-xs);
}
