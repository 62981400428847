// FORMS
:root {
  --form-focus-bg-color: rgb(230, 230, 230);
  --form-border: 1px solid rgb(200, 200, 200);
  --form-border-color-focus: rgb(200, 200, 200);
  --form-border-radius: 4px;
  --form-font-color: var(--fg-muted-color);
  --form-bg-color: var(--bg-elevated-color);
  --form-fg-color: var(--fg-muted-color);
  --form-placeholder-color: var(--fg-very-muted-color);
}

@media (prefers-color-scheme: dark) {
  :root {
    --form-focus-bg-color: rgb(80, 80, 80);
    --form-border: 1px solid rgb(110, 110, 110);
  }
}


$f-font-size: var(--fs-m);
$f-font-color: var(--form-fg-color);
$f-placeholder-font-color: var(--form-placeholder-color);

// Borders
$f-border-radius: var(--form-border-radius);
$f-border: var(--form-border);

// Inputs, Textareas, Select, Option
$f-input-height: 40px;
$f-input-width: 100%;
$f-input-max-width: 400px;
$f-input-bg-color: var(--form-bg-color);
$f-focus-bg-color: var(--form-focus-bg-color);

$f-focus-border-color: var(--form-border-color-focus);
$f-focus-font-color: $f-font-color;

// Layout
$f-vertical-margin: var(--s);


/* Global Reset Styles ------------------ */
input,
textarea,
select,
option,
optgroup,
legend,
fieldset {
  box-sizing: border-box;
  outline: none;

  font-size: $f-font-size;
  color: $f-font-color;
  vertical-align: top;

  display: block;
  margin: $f-vertical-margin 0;
}


datalist {
  font-size: $f-font-size;
}

label {
  display: block;
  font-size: $f-font-size;
  color: var(--form-fg-color);
  margin: $f-vertical-margin 0;
  user-select: none;
}


/* Input & Textarea ------------------ */

/* Fields with standard width */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list],
input[type="file"],
select,
textarea {
  width: $f-input-width;
  max-width: $f-input-max-width;
  padding: var(--s);
  background-color: $f-input-bg-color;

  border-radius: $f-border-radius;
  border: $f-border;
}

/* Fields with standard height */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list] {
  height: $f-input-height;
  appearance: none;

  &::placeholder {
    font-weight: normal;
    color: $f-placeholder-font-color;
  }
}

/* Other */

textarea {
  appearance: none;
  overflow: auto;
}

input[type="range"] {
  height: $f-input-height;
  width: $f-input-width;
  max-width: $f-input-max-width;
}

input[type="file"] {
  min-height: $f-input-height;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
  accent-color: var(--primary-color);
}


/* Select ------------------ */

select {
  height: $f-input-height;
  appearance: none;
}

select[multiple] {
  height: auto;
  min-height: $f-input-height;
  padding: 0;

  option {
    margin: 0;
    padding: var(--s);
  }
}

/* Fieldset ------------------ */

fieldset {
  padding: 0;
  border: 0;
}

legend {
  padding: 0;
  font-weight: inherit;
}

/* States ------------------ */
input[disabled],
textarea[disabled],
select[disabled],
option[disabled],
button[disabled] {
  cursor: not-allowed;
}

input:focus,
textarea:focus,
select:focus,
option:focus {
  background-color: $f-focus-bg-color;
  border-color: $f-focus-border-color;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: $f-focus-border-color solid 2px;
}
