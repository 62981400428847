.TaskInboxTask {
  margin-top: var(--s2);
  display: flex;
  align-items: center;
  position: relative;
  --task-color: var(--primary-color);

  //border: 1px solid black;
  > :last-child {
    margin-left: auto;
  }
}


.taskIcon {
  position: relative;
  margin-right: var(--timeline-right-margin);
  background: var(--bg-elevated-color);

  i {
    color: var(--task-color);
  }
}

.taskMiddleSection {
  cursor: pointer;
  text-align: left;
  padding: var(--s2) 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.taskTime {
  font-size: var(--fs-s);
  color: var(--fg-muted-color);
}

.taskTitle {
  margin-top: var(--ss);
  margin-bottom: var(--ss);
  font-size: var(--fs-l);
  font-weight: 700;

  .TaskInboxTask.isCompleted & {
    text-decoration: line-through;
    color: var(--fg-muted-color);
    opacity: 0.6
  }
}

.taskSubtask {
  color: var(--fg-muted-color);
}
