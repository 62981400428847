.TimelineAddTaskPrompt {
  margin-left: var(--timeline-total-size);
  font-size: var(--fs-s);
}

.msg {
  color: var(--fg-muted-color);
  vertical-align: baseline;

  > i {
    font-size: inherit;
    margin-right: var(--ss);
  }
}


.btns {
  margin-top: var(--s);
  margin-bottom: var(--s);
}
