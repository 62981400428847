.SelectDayBar {
}

.days {
  display: flex;
  width: 100%;
}

.day {
  padding: 16px;
  flex: 1 1 0;
  width: 0;
  cursor: pointer;
  text-align: center;
  user-select: none;
}

.dayName {
  color: var(--fg-muted-color);
  font-size: var(--fs-s);
  margin-bottom: var(--s);
}

.dayNr {
  display: inline-block;
  font-weight: bold;
  font-size: var(--fs-l);
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;

  .isSelected & {
    background: rgb(120, 120, 120);
    color: var(--contrast-fg-color);
  }

  .isToday & {
    color: var(--primary-color);
  }

  .isToday.isSelected & {
    background: var(--primary-color);
    color: var(--contrast-fg-color);
  }
}

.weekSelector {
  display: flex;
  padding: var(--s2);
  padding-bottom: var(--s);
  align-items: center;
  line-height: 40px;
}

.monthName {
  font-size: var(--fs-xxl);
  font-weight: bold;
  cursor: pointer;
}

.prevNextBtn {
  display: inline-block;
  margin-left: var(--s);
  cursor: pointer;
  width: 40px;
  line-height: 1;
  text-align: center;
  user-select: none;

  i {
    color: var(--primary-color);
    font-weight: bold;
  }


  &:first-child {
    margin-left: 0;
    margin-right: var(--s);
  }
}

.dayTaskPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-top: var(--s);
}

.dayTaskPreviewIcoWrapper {
  font-size: var(--fs-s);
  background: var(--task-color);
  color: var(--contrast-fg-color);
  width: 18px;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
