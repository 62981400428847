:root {
  --fg-color: rgb(20, 20, 20);
  --fg-muted-color: rgba(20, 20, 20, 0.5);
  --fg-very-muted-color: rgba(20, 20, 20, 0.25);
  --primary-color: rgb(230, 161, 154);
  --main-bg-color: rgb(255, 255, 255);
  --bg-elevated-color: rgb(240, 240, 240);
  --divider-color: rgb(180, 180, 180);
  --contrast-fg-color: rgb(255, 255, 255);
  --btn-border-radius: 8px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: rgb(40, 40, 40);
    --fg-color: rgb(230, 230, 230);
    --fg-muted-color: rgba(230, 230, 230, 0.5);
    --fg-very-muted-color: rgba(230, 230, 230, 0.25);
    --bg-elevated-color: rgb(60, 60, 60);
    --divider-color: rgb(100, 100, 100);
  }
}

// SPACES
:root {
  --ss: 4px;
  --s: 8px;
  --s2: 16px;
  --s3: 24px;
  --s4: 32px;
}

// FONT_SIZES
:root {
  --fs-s: 12px;
  --fs-m: 14px;
  --fs-l: 16px;
  --fs-xl: 22px;
  --fs-xxl: 26px;
}

// ICON SIZES
:root {
  --is-xs: 12px;
  --is-s: 20px;
  --is-m: 26px;
}

// Z_INDEX
:root {
  --z-add-task-modal: 22;
}

// TRANSITIONS
:root {
  --standard-transition-duration: 150ms
}

// OTHER
:root {
  --timeline-icon-size: 48px;
  --timeline-right-margin: 16px;
  // faster than using calc....
  --timeline-total-size: 64px;
  --add-task-modal-width: 440px;
}
