.EditTaskModal {
  display: block;
  max-width: var(--add-task-modal-width) !important;
  height: 100%;
}

.content {
  padding-top: var(--s2);
  overflow-y: auto;
  margin-bottom: 80px;
  margin-right: -16px;
  padding-right: 16px;
}
