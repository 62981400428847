@import "src/styles/variables";

.DayView {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


.dayViewContentOuterWrapper {
  min-height: 100%;
  flex-grow: 1;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background: var(--main-bg-color);
  padding-top: var(--s3);
  padding-bottom: var(--s3);
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.dayViewContentWrapper {
  max-width: 680px;
  margin: auto;
}
