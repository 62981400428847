@import "variables";
@import "utils/utils";
@import "base/base";
@import "components/components";


html {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  color: var(--fg-color);
  background: var(--main-bg-color);
  background: var(--bg-elevated-color);
}

*, *:before, *:after {
  box-sizing: inherit;
}
