$height: 30px;

.AddSubtaskInput {
  display: flex;
  margin: 0;
  // +1 pme fpr tje bprder
  height: $height+1;
  border-bottom: 1px solid var(--divider-color);

  > * {
    margin: 0;
  }

  i {
    font-size: inherit;
  }

  button {
    padding: 0;
    height: $height;
    line-height: $height;
    justify-content: center;
  }

  > input[type=checkbox] {
    margin-right: var(--s);
    accent-color: var(--primary-color);
    outline: none !important;
  }

  > input[type=text] {
    min-width: 80px;
    line-height: $height;
    height: $height;
    margin: 0;
    border: 0;
    border-radius: 0;
  }
}
