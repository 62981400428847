.TaskCheckbox {
  width: 26px;
  height: 26px;
  border: 3px solid var(--task-color);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 23px;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-weight: 700;
    font-size: 20px;
    display: block;
  }
}

.isChecked {
  background: var(--task-color);
}
