
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-family: roboto, "Droid Sans", "Consolas", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: var(--fs-xxl);
}

h2 {
  font-size: var(--fs-xl);
}

h3 {
  font-size: var(--fs-l);
}

h4 {
  font-size: var(--fs-m);
}


em {
  color: var(--primary-color);
  font-weight: 600;
  font-style: normal;
}

.medium-label {
  color: var(--fg-muted-color);
  font-weight: bold;
  margin-bottom: var(--ss);
}

.muted {
  color: var(--fg-muted-color);
}
