@import 'src/styles/variables';


.TimelineTask {
  display: flex;
  align-items: center;
  position: relative;
  --task-color: var(--primary-color);

  //border: 1px solid black;
  > :last-child {
    margin-left: auto;
  }
}

.taskIconInverse,
.taskIcon {
  user-select: none;
  width: var(--timeline-icon-size);
  min-height: var(--timeline-icon-size);
  border-radius: 30px;
  line-height: var(--timeline-icon-size);
  cursor: pointer;
  margin-top: var(--s2);
  margin-bottom: var(--s2);
  display: flex;
  justify-content: center;
  align-items: center;

  $overlapBdRadius: 4px;

  .TimelineTask.isOverlapBefore & {
    border-top-left-radius: $overlapBdRadius;
    border-top-right-radius: $overlapBdRadius;
    //margin-bottom: 0;
    //margin-top: 0;
  }

  .TimelineTask.isOverlapAfter & {
    border-bottom-left-radius: $overlapBdRadius;
    border-bottom-right-radius: $overlapBdRadius;
    //margin-bottom: 0;
    //margin-top: 0;
  }

}

.taskIconInverse {
  background: var(--task-color);
  position: absolute;
  mask-image: linear-gradient(180deg, black var(--progress), transparent var(--progress));
  mask-image: linear-gradient(180deg, black calc(-5% + var(--progress)), transparent calc(5% + var(--progress)));
  height: 100%;

  i {
    color: #fff !important;
  }

  .isFullProgress & {
    mask-image: none !important;
  }
}

.taskIcon {
  position: relative;
  margin-right: var(--timeline-right-margin);
  background: var(--bg-elevated-color);

  i {
    color: var(--task-color);
  }
}

.taskMiddleSection {
  cursor: pointer;
  text-align: left;
  padding: var(--s2) 0;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;

  .TimelineTask.isOverlapBefore & {
    // NOTE: to make room for the overlap msg
    //padding-top: 30px;
    //padding-bottom: 30px;
  }
}

.overlappingMsg {
  color: var(--fg-muted-color);
  white-space: nowrap;
  position: absolute;
  // NOTE magic number to center msg in-between
  top: -5px;
  left: 0;
  font-size: var(--fs-s);
}


.taskTime {
  font-size: var(--fs-s);
  color: var(--fg-muted-color);
  display: flex;
  align-items: center;

  i {
    margin-left: 4px;
  }
}

.taskTitle {
  margin-top: var(--ss);
  margin-bottom: var(--ss);
  font-size: var(--fs-l);
  font-weight: 700;

  .TimelineTask.isCompleted & {
    text-decoration: line-through;
    color: var(--fg-muted-color);
    opacity: 0.6
  }
}

.taskSubtask {
  color: var(--fg-muted-color);
}
