.TaskInbox {
  padding: var(--s2);
  position: relative;
}

.addInboxTaskBtn {
  top: var(--s2);
  position: absolute;
  right: var(--s2);
}

.undoneInboxTasks {

}

.doneInboxTasks {

}
