.mt {
  margin-top: var(--s);
}

.mt2 {
  margin-top: var(--s2);
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}
