@import 'src/styles/variables';

.Timeline {
  margin-top: var(--s2);
  margin-bottom: var(--s2);
  text-align: left;
}

.timelineRow {
  display: flex;

  > div:last-child {
    flex: 1;
  }
}

